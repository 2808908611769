import React, { useEffect, useState, lazy } from "react";
import { ClubLogo } from "../../ClubLogo";
import TraderCommentary from "./commentary";
import TraderScorecard from "./scorecard";
import { WinProbability } from "../../MatchDetail/Charts/WinProbability";
import Manhattan from "../../MatchDetail/Charts/Manhattan";
import Worm from "../../MatchDetail/Charts/Worm";
import Modal from "react-modal";
import WalletDisplay from "../../Squares/wallet-display";
import { Cookies } from "react-cookie-consent";
import { useUser } from "../../../UserContext";

const MatchPlay = ({ matchId }) => {
  const [liveBat, setLiveBat] = useState("");
  const [score, setScore] = useState(["-", "-", "-", "-"]);
  const [teams, setTeams] = useState(["", ""]);
  const [abbrTeams, setAbbrTeams] = useState(["", ""]);
  const [matchTitle, setMatchTitle] = useState("");
  const [innsNow, setInnsNow] = useState(1);
  const [batNow, setBatNow] = useState("");
  const [homeOvers, setHomeOvers] = useState(["0.0", "0.0"]);
  const [awayOvers, setAwayOvers] = useState(["0.0", "0.0"]);
  const [status, setStatus] = useState("");
  const [batting, setBattings] = useState(["", "", "", ""]);
  const [curBall, setCurBall] = useState(null);
  const [overDelivery, setOverDelivery] = useState([]);

  const [isScorecardOpen, setIsScorecardOpen] = useState(false);
  const [scorecardData, setScorecardData] = useState(null);
  const [striker, setStriker] = useState();
  const [bowling, setBowling] = useState();
  const [overs, setOvers] = useState("");
  const [complete, setComplete] = useState("");
  const [battingOvers, setBattingOvers] = useState([0, 0, 0, 0]);

  const [isCommentaryOpen, setIsCommentaryOpen] = useState(false);
  const [commentary, setCommentary] = useState([]);

  const [isChartsOpen, setIsChartsOpen] = useState(false);
  const [probability, setProbability] = useState([
    { name: "", probability: 0 },
    { name: "", probability: 0 },
  ]);
  const [mOvers, setMOvers] = useState([0, 0]);
  const [scoreGridData, setScoreGridData] = useState([]);

  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const [embedcode, setEmbedCode] = useState("");
  const [marketData, setMarketData] = useState([]);

  const wsUrl = process.env.FEED_URL;
  const apiKey = process.env.DECIMAL_API_KEY;

  const getEmbedCode = async (match_id) => {
    try {
      const response = await fetch(
        `${process.env.API_URL}/embeddedplayer/${match_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.API_KEY,
          },
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        setEmbedCode(data.data);
      }
    } catch (error) {
      //console.log(error);
      return null;
    }
  };

  async function getShortName(teamname) {
    const res = await fetch(
      process.env.API_URL + "/teams/shortname/" + teamname,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.API_KEY,
        },
      }
    );
    const data = await res.json();
    return data.shortname;
  }

  async function subscribeToMatch(match_id) {
    let socket;
    if (process.env.ENVIRONMENT !== "dev") {
      socket = new WebSocket(`${wsUrl}`);
    } else {
      socket = new WebSocket(
        `${wsUrl}/${match_id}/${apiKey}/APIFEED-${match_id}`
      );
    }
    socket.addEventListener("open", function open() {
      const message = JSON.stringify({
        feed_filter: [
          "scorecard",
          "scoreboard",
          "commentary",
          "market_price_update",
          "scoregrid",
          "event",
        ],
        msg_type: "feed_subscription",
        feed_id: match_id,
      });
      socket.send(message);
    });

    socket.addEventListener("message", async function incoming(data) {
      const parsedData = JSON.parse(data.data);
      if (parsedData.event) {
        const winner =
          parsedData.event.scoreboard.find((item) => item.name === "winner")
            ?.value ?? "NA";
        const curOvs =
          parsedData.event.scoreboard.find(
            (item) => item.name === "Current Innings Overs"
          )?.value ?? "";
        const curDelv =
          parsedData.event.scoreboard.find(
            (item) => item.name === "current.delv"
          )?.value ?? "";
        const matchComment =
          parsedData.event.scoreboard.find(
            (item) => item.name === "match_comment"
          )?.value ?? "";
        const gamePhase =
          parsedData.event.scoreboard.find((item) => item.name === "game_phase")
            ?.value ?? "";
        const currentBowler =
          parsedData.event.scoreboard.find((item) => item.name === "bowler")
            ?.value ?? "";
        const currentBatting =
          parsedData.event.scoreboard.find(
            (item) => item.name === "Current Batting Team"
          )?.value ?? "";
        const batsman1 =
          parsedData.event.scoreboard.find(
            (item) => item.name === currentBatting + "Bat.1"
          )?.value ?? "";
        const batsman2 =
          parsedData.event.scoreboard.find(
            (item) => item.name === currentBatting + "Bat.2"
          )?.value ?? "";

        const strike1 = parsedData.event.scoreboard.find(
          (item) => item.name === "Batsman1 Strike"
        );
        const strike2 = parsedData.event.scoreboard.find(
          (item) => item.name === "Batsman2 Strike"
        );

        let ballStatus = "";
        if (strike1 ? strike1.value : "1" === "1") {
          ballStatus = currentBowler + " to " + batsman1;
        }
        if (strike2 ? strike2.value : "1" === "1") {
          ballStatus = currentBowler + " to " + batsman2;
        }
        if (winner === "NA") {
          setStatus(ballStatus);
          setCurBall(curOvs + "." + curDelv);
        } else {
          setStatus(winner + " wins.");
          setCurBall(null);
        }
      }
      if (parsedData.scoregrid) {
        setScoreGridData(parsedData.scoregrid);
        let found = false;

        for (let i = 4; i >= 1 && !found; i--) {
          let thisInns = parsedData.scoregrid.overs["inns" + i];
          if (thisInns) {
            for (let ov = thisInns.length - 1; ov >= 0; ov--) {
              if (thisInns[ov].bowler !== "" && thisInns[ov].bowlerid !== "") {
                setOverDelivery([
                  thisInns[ov].b1,
                  thisInns[ov].b2,
                  thisInns[ov].b3,
                  thisInns[ov].b4,
                  thisInns[ov].b5,
                  thisInns[ov].b6,
                  thisInns[ov].b7,
                  thisInns[ov].b8,
                  thisInns[ov].b9,
                  thisInns[ov].b10,
                  thisInns[ov].b11,
                  thisInns[ov].b12,
                ]);
                found = true;
                break;
              }
            }
          }
        }
      }

      if (parsedData.commentary) {
        setCommentary((prevCommentary) => {
          const updatedCommentary = {
            ...prevCommentary,
            commentaries: { ...(prevCommentary?.commentaries || {}) },
          };

          Object.keys(parsedData.commentary.commentaries).forEach(
            (inningsKey) => {
              const newInningsCommentary =
                parsedData.commentary.commentaries[inningsKey];
              if (newInningsCommentary !== null) {
                updatedCommentary.commentaries[inningsKey] = [
                  ...(updatedCommentary.commentaries[inningsKey] || []),
                  ...newInningsCommentary,
                ];
              }
            }
          );
          return updatedCommentary;
        });
      }
      if (parsedData.market_price_update) {
        const winPredict = parsedData.market_price_update.markets.find(
          (market) => market.market_id === "1010"
        );
        if (winPredict) {
          let prob1 = Math.round(winPredict.selections[0].probability * 100);
          let prob2 = 100 - prob1;
          setProbability([
            { name: winPredict.selections[0].name, probability: prob1 },
            { name: winPredict.selections[1].name, probability: prob2 },
          ]);
        }
      }
      if (parsedData.scoreboard) {
        setBattings([
          parsedData.scoreboard.innings1battingteam,
          parsedData.scoreboard.innings2battingteam,
          parsedData.scoreboard.innings3battingteam,
          parsedData.scoreboard.innings4battingteam,
        ]);
        let stat = parsedData.scoreboard?.status.split("-")[0];
        if (
          parsedData.scoreboard.innings1battingteam ===
          parsedData.scoreboard.home
        ) {
          setHomeOvers([
            parsedData.scoreboard.innings1overs,
            parsedData.scoreboard.innings3overs,
          ]);
          setAwayOvers([
            parsedData.scoreboard.innings2overs,
            parsedData.scoreboard.innings4overs,
          ]);
        } else {
          setAwayOvers([
            parsedData.scoreboard.innings1overs,
            parsedData.scoreboard.innings3overs,
          ]);
          setHomeOvers([
            parsedData.scoreboard.innings2overs,
            parsedData.scoreboard.innings4overs,
          ]);
        }
        setMOvers([
          parsedData.scoreboard.innings1max,
          parsedData.scoreboard.innings2max,
          parsedData.scoreboard.innings3max,
          parsedData.scoreboard.innings4max,
        ]);
      }
      if (parsedData.scorecard) {
        setInnsNow(parsedData.scorecard.inns_now);
        setBatNow(parsedData.scorecard.bat_now);
        const matchParts = parsedData.scorecard.description.split(",");
        setMatchTitle(matchParts.length >= 3 ? matchParts[1].trim() : "");
        setTeams(matchParts[0].split(" v "));
        const status = parsedData.scorecard.event_status.split(" - ");

        if (abbrTeams[0] === "" || abbrTeams[1] === "") {
          let home = await getShortName(matchParts[0].split(" v ")[0]);
          let away = await getShortName(matchParts[0].split(" v ")[1]);
          setAbbrTeams([home, away]);
        }
        let s1 = "0/0",
          s2 = "0/0",
          s3 = "0/0",
          s4 = "0/0";
        if (parsedData.scorecard.inns1 !== null) {
          s1 =
            parsedData.scorecard.inns1.runs +
            "/" +
            parsedData.scorecard.inns1.wkts;
        }
        if (parsedData.scorecard.inns2 !== null) {
          s2 =
            parsedData.scorecard.inns2.runs +
            "/" +
            parsedData.scorecard.inns2.wkts;
        }
        if (parsedData.scorecard.inns3 !== null) {
          s3 =
            parsedData.scorecard.inns3.runs +
            "/" +
            parsedData.scorecard.inns3.wkts;
        }
        if (parsedData.scorecard.inns4 !== null) {
          s4 =
            parsedData.scorecard.inns4.runs +
            "/" +
            parsedData.scorecard.inns4.wkts;
        }
        let insScore = [s1, s2, s3, s4];
        let innsnow = parsedData.scorecard.inns_now;
        let oppnow = 1;
        let rptNow = 2;
        if (innsnow === 1 || innsnow === 3) {
          oppnow = 2;
          rptNow = 1;
        }
        setLiveBat(parsedData.scorecard.bat_now);
        if (parsedData.scorecard.bat_now === matchParts[0].split(" v ")[0]) {
          setScore([
            insScore[rptNow - 1],
            insScore[oppnow - 1],
            insScore[rptNow + 1],
            insScore[oppnow + 1],
          ]);
        } else {
          setScore([
            insScore[oppnow - 1],
            insScore[rptNow - 1],
            insScore[oppnow + 1],
            insScore[rptNow + 1],
          ]);
        }
        setScorecardData(parsedData.scorecard);
      }
    });
    socket.addEventListener("error", function error(error) {
      //console.error('WebSocket error:', error);
    });
    socket.addEventListener("close", function close(event) {
      //console.log('Disconnected from the WebSocket server:', event);
      //console.log(`Close code: ${event.code}, reason: ${event.reason}`);
    });
  }

  useEffect(() => {
    getEmbedCode(matchId);
    subscribeToMatch(matchId);
  }, [matchId]);

  const session_token = Cookies.get("c8_session_token");

  const [iframeSrc, setIframeSrc] = useState("");
  const [showWallet, setShowWallet] = useState(false);
  const [userAccount, setUserAccount] = useState({
    id: "",
    username: "",
    profile_pic: "",
    wallet: {
      coins: "",
      rubies: "",
    },
  });
  useEffect(() => {
    const fetchAccount = async () => {
      const response = await fetch(
        `${process.env.API_URL}/account/${session_token}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.API_KEY,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setUserAccount({
          username: data.data.username,
          profile_pic: data.data.profile_pic,
          id: data.data.wallet.account_id,
          wallet: {
            coins: data.data.wallet.coins,
            rubies: data.data.wallet.rubies,
          },
        });
        setShowWallet(true);
      }
    };
    fetchAccount();
    if (!user) {
      setShowWallet(true);
    }
  }, []);

  useEffect(() => {
    setIframeSrc(
      `${process.env.GAMES_URL}?userId=${userAccount.id}&userName=${
        userAccount.username
      }&profilePic=${encodeURIComponent(
        userAccount.profile_pic
      )}&sessionToken=${session_token}`
    );
  }, [userAccount]);

  const { user } = useUser();

  return (
    <div className="w-full">
      <div className="mt-[25px] mb-[20px]"></div>
      <div
        className="flex flex-row cursor-pointer"
        onClick={() => {
          location.href = "/trader";
        }}
      >
        <div className="flex flex-row justify-centet items-center px-2 lg:px-14">
          <div className="w-[25px] h-[25px] bg-[#d2ff00] text-[#1b062f] flex justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25px"
              height="25px"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="m14 18l-6-6l6-6l1.4 1.4l-4.6 4.6l4.6 4.6z"
              />
            </svg>
          </div>
          <div className="h-[25px] font-anton text-[#1b062f] text-[14px] px-[8px] pt-[3px] ml-[2px] bg-[#d2ff00]">
            LOBBY
          </div>
        </div>
        <WalletDisplay
          wallet={userAccount.wallet}
          showWallet={showWallet}
          profilePic={undefined}
          username={undefined}
          goBack={undefined}
        />
      </div>

      <div
        className="w-full bg-cover bg-center p-[10px]"
        style={{
          backgroundImage: `url(${process.env.CDN_ENDPOINT}/assets/cricketstadium-blend.png)`,
        }}
      >
        <div className="flex">
          <div className="flex-grow font-chakra text-[14px]">{matchTitle}</div>
        </div>

        <div className="relative flex gap-5 mt-[20px]">
          <div className="relative bg-main60 h-[50px] w-full flex">
            <div className="bg-white h-[50px] w-[50px] flex items-center justify-center">
              <ClubLogo teamName={teams[0]} height={"40px"} width={"40px"} />
            </div>
            <div className="flex-grow text-center pt-[3px]">
              <div className="font-chakra font-bold font-[20px]">
                {score[2] !== "0/0" ? (
                  <>
                    {score[0].split("/")[0]} &amp; {score[2]}
                  </>
                ) : (
                  <>{score[0]}</>
                )}
              </div>
              <div className="font-chakra font-normal text-[12px] opacity-[50%] lowercase">
                {homeOvers[1] === "0.0" ? homeOvers[0] : homeOvers[1]} ov.
              </div>
            </div>
            {liveBat === teams[0] && (
              <div className="absolute bottom-[0px] h-[3px] w-full bg-pink" />
            )}
          </div>
          <div className="relative bg-main60 h-[50px] w-full flex">
            <div className="flex-grow text-center pt-[3px]">
              <div className="font-chakra font-bold font-[20px]">
                {score[3] !== "0/0" ? (
                  <>
                    {score[1].split("/")[0]} &amp; {score[3]}
                  </>
                ) : (
                  <>{score[1]}</>
                )}
              </div>
              <div className="font-chakra font-normal text-[12px] opacity-[50%] lowercase">
                {awayOvers[1] === "0.0" ? awayOvers[0] : awayOvers[1]} ov.
              </div>
            </div>
            <div className="bg-white h-[50px] w-[50px] flex items-center justify-center">
              <ClubLogo teamName={teams[1]} height={"40px"} width={"40px"} />
            </div>
            {liveBat === teams[1] && (
              <div className="absolute bottom-[0px] h-[3px] w-full bg-pink" />
            )}
          </div>
          <div className="absolute top-[10px] left-[calc(50%_-_15px)] -translate-x-[calc(50%_-_15px)] w-[30px] h-[30px] bg-lime text-main font-anton text-[14px] flex items-center justify-center">
            VS
          </div>
        </div>

        <div className="mt-[10px] w-full bg-main60 h-[40px] font-chakra text-[12px] font-normal normal-case flex">
          <div className="pt-[11px] pl-[10px] flex-grow truncacte text-ellipsis">
            {curBall && <span className="text-pink pr-[5px]">{curBall}</span>}
            {status}
          </div>
          <div className="flex pt-[3px] pr-[3px]">
            {overDelivery
              .slice()
              .reverse()
              .map((ovDel, i) => {
                return (
                  <>
                    {ovDel !== null && ovDel !== "" && (
                      <div className="w-[34px] h-[34px] bg-white text-main text-chakra text-[12px] text-center pt-[8px] mr-[2px] font-bold">
                        {ovDel}
                      </div>
                    )}
                  </>
                );
              })}
          </div>
        </div>

        <div className="mt-[10px] w-full flex font-chakra text-white gap-2 text-[14px]">
          <div
            className="bg-main60 h-[30px] px-[10px] pt-[4px] hover:bg-lime hover:text-main cursor-pointer"
            onClick={() => {
              setIsScorecardOpen(true);
            }}
          >
            SCORECARD
          </div>
          <div
            className="bg-main60 h-[30px] px-[10px] pt-[4px] hover:bg-lime hover:text-main cursor-pointer"
            onClick={() => {
              setIsCommentaryOpen(true);
            }}
          >
            COMMENTARY
          </div>
          <div
            className="bg-main60 h-[30px] px-[10px] pt-[4px] hover:bg-lime hover:text-main cursor-pointer"
            onClick={() => {
              setIsChartsOpen(true);
            }}
          >
            CHARTS
          </div>
          {embedcode !== "" && (
            <div
              className="bg-main60 h-[30px] px-[10px] pt-[4px] hover:bg-lime hover:text-main cursor-pointer"
              onClick={() => {
                setIsVideoOpen(true);
              }}
            >
              VIDEO
            </div>
          )}
        </div>
      </div>

      <Modal
        isOpen={isScorecardOpen}
        onRequestClose={() => setIsScorecardOpen(false)}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "400px",
            maxHeight: "600px",
            minHeight: "600px",
            background: "#1b062f",
            textAlign: "left",
            color: "#fff",
            padding: "0",
            overflowY: "auto",
          },
        }}
        contentLabel="Commentary"
        ariaHideApp={false}
      >
        <div>
          {isScorecardOpen && scorecardData && (
            <TraderScorecard
              scorecard={scorecardData}
              innsNow={innsNow}
              batNow={batNow}
              teams={teams}
              closeModal={() => {
                setIsScorecardOpen(false);
              }}
            />
          )}
        </div>
      </Modal>

      <Modal
        isOpen={isCommentaryOpen}
        onRequestClose={() => setIsCommentaryOpen(false)}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "400px",
            maxHeight: "600px",
            minHeight: "600px",
            background: "#1b062f",
            textAlign: "left",
            color: "#fff",
            padding: "0",
            overflowY: "auto",
          },
        }}
        contentLabel="Commentary"
        ariaHideApp={false}
      >
        <div>
          {isCommentaryOpen && (
            <TraderCommentary
              commentary={commentary}
              innsNow={innsNow}
              batNow={batNow}
              teams={teams}
              closeModal={() => {
                setIsCommentaryOpen(false);
              }}
            />
          )}
        </div>
      </Modal>

      <Modal
        isOpen={isVideoOpen}
        onRequestClose={() => setIsVideoOpen(false)}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "400px",
            maxHeight: "200px",
            minHeight: "200px",
            background: "#1b062f",
            textAlign: "left",
            color: "#fff",
            padding: "0",
            overflowY: "auto",
          },
        }}
        contentLabel="Video"
        ariaHideApp={false}
      >
        <div>
          {isVideoOpen && (
            <div className="w-100 bg-modal">
              <div className="relative flex justify-center">
                <div className="fit-content text-center bg-white text-main font-anton text-[14px] p-[5px]">
                  VIDEO
                </div>
                <div
                  className="absolute right-[5px] top-[3px] text-right cursor-pointer"
                  onClick={() => setIsVideoOpen(false)}
                >
                  <svg
                    fill="#fff"
                    height="10px"
                    width="10px"
                    viewBox="0 0 460.775 460.775"
                  >
                    <path
                      d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55
                                            c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55
                                            c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505
                                            c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55
                                            l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719
                                            c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"
                    />
                  </svg>
                </div>
              </div>
              <div className="p-[10px] max-h-[575px] overflow-y-auto">
                <iframe
                  width="100%"
                  height="100%"
                  src={`https://www.decimalsports.com/embeddedplayer/?id=${embedcode}`}
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          )}
        </div>
      </Modal>

      <Modal
        isOpen={isChartsOpen}
        onRequestClose={() => setIsChartsOpen(false)}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "400px",
            maxHeight: "600px",
            minHeight: "600px",
            background: "#1b062f",
            textAlign: "left",
            color: "#fff",
            padding: "0",
            overflowY: "auto",
          },
        }}
        contentLabel="Charts"
        ariaHideApp={false}
      >
        <div>
          {isChartsOpen && (
            <div className="w-100 bg-modal">
              <div className="relative flex justify-center">
                <div className="fit-content text-center bg-white text-main font-anton text-[14px] p-[5px]">
                  CHARTS
                </div>
                <div
                  className="absolute right-[5px] top-[3px] text-right cursor-pointer"
                  onClick={() => setIsChartsOpen(false)}
                >
                  <svg
                    fill="#fff"
                    height="10px"
                    width="10px"
                    viewBox="0 0 460.775 460.775"
                  >
                    <path
                      d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55
                                            c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55
                                            c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505
                                            c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55
                                            l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719
                                            c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"
                    />
                  </svg>
                </div>
              </div>
              <div className="p-[10px] max-h-[575px] overflow-y-auto">
                <div className="mt-[10px] flex justify-center">
                  <WinProbability
                    teams={teams}
                    probability={probability}
                    maxovers={mOvers}
                  />
                </div>
                <div className="mt-[10px] flex justify-center">
                  <Worm
                    teams={teams}
                    scoregrid={scoreGridData}
                    maxovers={mOvers}
                  />
                </div>
                <div className="mt-[10px] flex justify-center">
                  <Manhattan
                    teams={teams}
                    scoregrid={scoreGridData}
                    maxovers={mOvers}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default MatchPlay;
